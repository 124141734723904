import { createGlobalState } from "@vueuse/core"
import { Workbox } from "workbox-window"
import { ref, computed } from "vue"

export const useCacheManager = createGlobalState(() => {
  // Global state
  const wb = ref(null)
  const registration = ref(null)
  const status = ref("pending") // pending, initializing, ready, error
  const lastOperation = ref(null)
  const isOperationPending = ref(false)
  const currentError = ref(null)
  const initializePromise = ref(null)

  // Computed properties
  const isReady = computed(() => status.value === "ready")
  const isInitializing = computed(() => status.value === "initializing")
  const hasError = computed(() => status.value === "error")

  // Function to send a message to the Service Worker using MessageChannel
  const sendMessage = async (message) => {
    if (!registration.value) {
      throw new Error("Service Worker not registered")
    }

    return new Promise((resolve, reject) => {
      const messageChannel = new MessageChannel()
      messageChannel.port1.onmessage = (event) => {
        if (event.data.error) {
          reject(event.data.error)
        } else {
          resolve(event.data)
        }
      }

      const activeWorker = registration.value.active
      if (!activeWorker) {
        reject(new Error("No active service worker"))
        return
      }

      activeWorker.postMessage(message, [messageChannel.port2])
    })
  }

  // Wrapper for operations with state management
  const executeOperation = async (operation, name) => {
    try {
      // If an initialization is in progress, wait for it to finish
      if (initializePromise.value) {
        await initializePromise.value
      }
      // If not initialized and no initialization in progress, start initialization
      else if (!isReady.value) {
        await initialize()
      }

      // If still not ready after initialization, it's an error
      if (!isReady.value) {
        throw new Error("Cache manager not initialized")
      }

      isOperationPending.value = true
      lastOperation.value = name

      const result = await operation()
      return result
    } catch (error) {
      currentError.value = error
      throw error
    } finally {
      isOperationPending.value = false
    }
  }

  // Initialization using Workbox
  const initialize = async (forceReinit = false) => {
    if (initializePromise.value && !forceReinit) {
      return initializePromise.value
    }

    initializePromise.value = new Promise((resolve) => {
      const init = async () => {
        try {
          status.value = "initializing"
          currentError.value = null

          // Create a new Workbox instance
          wb.value = new Workbox("/service-worker.js", {
            ...(process.env.NODE_ENV === "development" && {
              updateViaCache: "none",
            }),
          })

          // Listen to Workbox events
          wb.value.addEventListener("activated", (event) => {
            status.value = "ready"
            if (!event.isUpdate) {
              console.log("Service Worker activated for the first time")
            }
          })

          wb.value.addEventListener("controlling", () => {
            console.log("Service Worker is taking control")
          })

          wb.value.addEventListener("waiting", () => {
            console.log("New version of Service Worker is waiting")
          })

          // Register the Service Worker
          registration.value = await wb.value.register()
          console.log("Service Worker registered successfully")

          // Wait for the service worker to become active
          if (registration.value.active) {
            status.value = "ready"
          } else {
            await new Promise(resolveActivation => {
              wb.value.addEventListener("activated", () => {
                status.value = "ready"
                resolveActivation()
              })
            })
          }

          // Test the connection
          await testConnection()

          resolve(true)
        } catch (error) {
          console.error("Error during initialization:", error)
          status.value = "error"
          currentError.value = error
          resolve(false)
        } finally {
          initializePromise.value = null
        }
      }

      init()
    })

    return initializePromise.value
  }

  // Public API
  const verifyCache = (urls, quizId) => {
    return executeOperation(
      () => sendMessage({ type: "VERIFY_CACHE", urls, quizId }),
      "verifyCache"
    )
  }

  const preloadDocuments = (urls, quizId) => {
    return executeOperation(
      () => sendMessage({ type: "PRELOAD_DOCUMENTS", urls, quizId }),
      "preloadDocuments"
    )
  }

  const clearCache = (quizId) => {
    return executeOperation(
      () => sendMessage({ type: "CLEAR_EXAM_CACHE", quizId }),
      "clearCache"
    )
  }

  const clearAllQuizCaches = () => {
    return executeOperation(
      () => sendMessage({ type: "CLEAR_ALL_QUIZ_CACHES" }),
      "clearAllQuizCaches"
    )
  }

  const testConnection = async () => {
    try {
      const response = await sendMessage("PING")
      return response === "PONG"
    } catch (error) {
      console.error("Error connecting to the Service Worker:", error)
      throw error
    }
  }

  // Force an update of the Service Worker
  const update = async () => {
    if (registration.value) {
      await registration.value.update()
    }
  }

  // In development, periodically check for updates
  if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    setInterval(update, 5000)
  }

  return {
    // State
    status: computed(() => status.value),
    lastOperation,
    isOperationPending,
    currentError,

    // Computed
    isReady,
    isInitializing,
    hasError,

    // Methods
    initialize,
    verifyCache,
    preloadDocuments,
    clearCache,
    clearAllQuizCaches,
    testConnection,
    update,
  }
})
