import { useStore } from "@/store"
import { computed, ref, watch } from "vue"
import instanceNodesModels from "@/store/modules/quizzes/instances/instance_nodes_models"
import { useCacheManager } from "@/composables/useCacheManager"
import { createGlobalState, useIntervalFn } from "@vueuse/core"
import objectHelpers from "@/helpers/object_helpers"
import trackingHelpers from "@/helpers/tracking_helpers"
// Global subject cache state with integrated cache status
const globalSubjectCache = createGlobalState(() => {
  const store = useStore()
  const {
    verifyCache,
    preloadDocuments: preloadDocumentCache,
    clearCache: clearDocumentCache,
  } = useCacheManager()

  // Reactive references
  const currentQuizId = ref(null)
  const currentUser = ref(null)
  const cacheStatus = ref(null)

  const documents = computed(() => {
    if (!currentQuizId.value) return []
    return store.getters.quizzesDocumentsByQuizId(currentQuizId.value)
  })

  const quizInstance = computed(() => {
    if (!currentQuizId.value || !currentUser.value) return null
    return store.getters.quizInstanceByQuizId2(currentQuizId.value, currentUser.value.id)
  })

  const filesUrlsToCache = computed(() => {
    const documentUrls = documents.value.map(doc => doc.url)
    const imageUrls = quizInstance.value ? instanceNodesModels.getQuizInstanceImageUrls(quizInstance.value) : []
    return [...new Set([...documentUrls, ...imageUrls])]
  })

  const checkCache = async () => {
    if (!filesUrlsToCache.value.length || !currentQuizId.value) return
    const status = await verifyCache(filesUrlsToCache.value, currentQuizId.value)
    if (!objectHelpers.areEqual(cacheStatus.value, status.status)) {
      trackingHelpers.trackEvent("cacheStatusChanged", {
        quizId: currentQuizId.value,
        status: status.status,
      })
      console.log("Exam asset cached", status.status)
    }
    cacheStatus.value = status.status
  }

  // Set up global interval check
  useIntervalFn(async () => {
    if (filesUrlsToCache.value.length && currentQuizId.value) {
      checkCache()
    }
  }, 1000)

  // Watch for changes in documents and quiz instance
  watch([documents, quizInstance], () => {
    checkCache()
  })

  const preloadDocuments = () => {
    // console.log({ fileUrlsToCache: filesUrlsToCache.value, currentQuizId: currentQuizId.value })
    if (!filesUrlsToCache.value.length || !currentQuizId.value) return
    preloadDocumentCache(filesUrlsToCache.value, currentQuizId.value)
  }

  const clearCache = () => {
    if (!currentQuizId.value) return
    clearDocumentCache(currentQuizId.value)
  }

  // Function to initialize or update the current quiz and user
  /**
   * Initialize or update the current quiz and user
   * @param {string|number} quizId - The ID of the quiz
   * @param {Object} user - The user object
   */
  const init = (quizId, user) => {
    currentQuizId.value = quizId
    currentUser.value = user
  }

  return {
    init,
    documents,
    quizInstance,
    filesUrlsToCache,
    checkCache,
    preloadDocuments,
    clearCache,
    cacheStatus,
  }
})

export const useSubjectCache = (quizId, user) => {
  const globalCache = globalSubjectCache()

  if (quizId && user) {
    globalCache.init(quizId, user)
  }

  return globalCache
}
